import * as React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export function ProtectedRoute(props) {
    const location = useLocation()
    if (!props.canUseRouteIf) {
        return <Navigate to={[
            props.ifNotAllowedPath || '/',
            location.pathname != '/' ? '?redirect=' : null,
            location.pathname != '/' ? location.pathname : null
        ].filter(Boolean).join('')} replace />
    }
    return <>{props.children || <Outlet />}</>
}
