import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubdivisonsInfo, getallSubdivisions } from '../../features/implementers/ImplemetnersSlice'
import ColumnSubdivision from './ColumnSubdivision'
import Loading from '../loading/Loading'
import { createNotify } from '../../global/utils/utils'
import ChoiceHeader from './ChoiceHeader'
import './ChoiseSubdivisions.css'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../features/auth/newAuth'

const ChoiceSubdivisions = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { allSubdivisionsList, allSubdivisionsUUIDs } = useSelector(state => state.implementers)
    const { isLoading, } = useSelector(state => state.ui)
    const [choicesList, setChoicesList] = useState([])

    const prevChoice = JSON.parse(localStorage.getItem('previous_choice'))

    const changingList = (uuid) => {
        if (choicesList.includes(uuid)) setChoicesList(choicesList.filter(id => uuid !== id))
        else setChoicesList([...choicesList, uuid])
    }


    const reset = () => setChoicesList([])
    const choiceAll = () => setChoicesList(allSubdivisionsUUIDs)
    const showData = () => {
        if (choicesList?.length) dispatch(getSubdivisonsInfo({ list_uuids: choicesList, navigate }))
        else createNotify('Пожалуйста, выберите подразделение')
    }
    const logOut = () => {
        dispatch(logout())
        navigate('/login', { replace: true })
    }

    const choiceGroupByID = (id) => {
        const groupChoiced = allSubdivisionsList[id - 1].list.reduce((acc, sub) => [...acc, sub.subdivision_uuid], [])
        setChoicesList(Array.from(new Set([...choicesList, ...groupChoiced])))
    }
    const cancleGroupByID = (id) => {
        const groupCancled = allSubdivisionsList[id - 1].list.reduce((acc, sub) => [...acc, sub.subdivision_uuid], [])
        const removedGroup = choicesList.filter((uuid) => !groupCancled.includes(uuid))
        setChoicesList(removedGroup)
    }

    useEffect(() => {
        localStorage.setItem('previous_choice', JSON.stringify(choicesList))
    }, [choicesList])

    useEffect(() => {
        if (!allSubdivisionsList.length) dispatch(getallSubdivisions())
        if (prevChoice) setChoicesList(prevChoice)
    }, [])

    if (isLoading) return <Loading />

    return (<>
        <div className='choice_subdivisions'>
            <ChoiceHeader reset={reset} choiceAll={choiceAll} showData={showData} logOut={logOut} />

            <div className="subdivisions_choice_list">
                <div className="subdivisions_choice_column">
                    {
                        allSubdivisionsList.filter((sub, i) => i < 3).map((subdivisions, i) =>
                            (< ColumnSubdivision key={i} choicesList={choicesList} subdivisions={subdivisions} changingList={changingList} choiceGroupByID={choiceGroupByID} cancleGroupByID={cancleGroupByID} />))
                    }
                </div>

                <div className="subdivisions_choice_column">
                    {
                        allSubdivisionsList.filter((sub, i) => i > 2 && i < 6).map((subdivisions, i) =>
                            (< ColumnSubdivision key={i} choicesList={choicesList} subdivisions={subdivisions} changingList={changingList} choiceGroupByID={choiceGroupByID} cancleGroupByID={cancleGroupByID} />))
                    }
                </div>

                <div className="subdivisions_choice_column" onClick={() => { }}>
                    {
                        allSubdivisionsList.filter((sub, i) => i > 5 && i < 9).map((subdivisions, i) =>
                            (< ColumnSubdivision key={i} choicesList={choicesList} subdivisions={subdivisions} changingList={changingList} choiceGroupByID={choiceGroupByID} cancleGroupByID={cancleGroupByID} />))
                    }
                </div>

            </div>

        </div>
    </>

    )
}

export default ChoiceSubdivisions