export const errorCodes = {
    'account_currency.currency-not-found': 'валюта не найдена',
    'accounting_banks.bank-not-found': 'Банк не найден',
    'auth.invalid-token-type': 'Неверный тип токена',
    'auth.invalid-token': 'Срок действия токена истек',
    'auth.session-expired': 'Срок действия токена истек',
    'auth.unauthorized': 'Неправильный логин или пароль',
    'categories.task-category-already-set': 'Категория уже задана для этой заявки',
    'clients_role.role-not-found': 'Роль клиента не найдена',
    'clients.client-not-found': 'Клиент не найден',
    'companies.company-not-found': 'Компания не найдена',
    'companies.company-with-that-bin-already-exists': 'Компания с такой корзиной уже существует',
    'companies.logo-not-found': 'Логотип не найден',
    'company_relation_types.relation_type-not-found': 'Тип связи не найден',
    'company-bills.bill-not-found': 'Билл не найден',
    'company-bills.cant-delete-main-account': 'Не могу удалить основной аккаунт',
    'contact_info.client-contact-info-not-found': 'Контактная информация клиента не найдена',
    'contact_info.company-contact-info-not-found': 'Контактная информация компании не найдена',
    'contact_info.contact-info-not-found': 'Контактная информация не найдена',
    'contact_info.user-contact-info-not-found': 'Контактная информация пользователя не найдена',
    'country_of_residence.country-of-residence-not-found': 'Страна проживания не найдена',
    'Devices.device-not-found': 'Устройство не найдено',
    'email.service-errored': 'Ошибка службы электронной почты',
    'email.service-not-available': 'Служба электронной почты недоступна',
    'enum_additional_work.additional-work-not-found': 'Дополнительная работа не найдена',
    'enum_issue_invoices.issue-invoice-not-found': 'Проблема Счет не найден',
    'extra.value-is-not-allowed': 'Дополнительная стоимость не допускается',
    'files.file-not-found': 'Файл не найден',
    'organizations.organization-not-found': 'Организация не найдена',
    'presets.preset-not-found': 'Предустановка не найдена',
    'projects.project-not-found': 'Проект не найден',
    'Regions.region-not-found': 'Регион не найден',
    'sequelize.error': 'Секвелизация ошибки ограничения ForeignKey',
    'service_catalog.Service-not-found': 'Сервис не найден',
    'task_bills.bill-not-found': 'Таскбилл не найден',
    'task_cancel_reasons.cancel-reason-not-found': 'Причина отмены не найдена',
    'task_statuses.status-not-found': 'Статус не найден',
    'tasks.cant-close-task-without-client-email': 'Нельзя закрыть заявку без почты "Рабочий е-mail" контактного лица',
    'tasks.cant-close-task-without-client': 'Нельзя закрыть заявку без клиента',
    'tasks.cant-close-task-without-outcome': 'Нельзя закрыть заявку без результата',
    'tasks.cant-delete-task-without-outcome': 'Нельзя удалить заявку без результата',
    'tasks.cant-redirect-task-without-user': 'Невозможно перенаправить заявку без пользователя',
    'tasks.optimistic-lock-failed': 'Данные были изменены кем-то другим, перезагрузите страницу',
    'tasks.task-not-found': 'Задача не найдена',
    'unit_measurements.unit-not-found': 'Объект не найден',
    'users_statuses.status-reason-not-found': 'Причина статуса не найдена',
    'users_statuses.status-type-not-found': 'Тип статуса не найден',
    'users_subdivisions.subdivision-not-found': 'Подразделение не найдено',
    'users.user-not-found': 'Пользователь не найден',
    'validation.error': 'Ошибка проверки тела',
    'http.too-many-requests': 'Слишком много запросов, попробуйте позже',
}

export const checkErrorCode = (resp, defaultText) => {
    if (typeof resp === 'string') {
        return resp in errorCodes ? errorCodes[resp] : defaultText
    }
    if (!Array.isArray(resp) && typeof resp === 'object') {
        return 'code' in resp && resp.code in errorCodes ? errorCodes[resp.code] : defaultText
    }
}