import axios from 'axios';
import { apiConfig } from '../config';

export const API = {
    CRM: {
        AXIOS: (() => {
            const privateApi = axios.create({
                baseURL: apiConfig.webCrmApi,
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });

            privateApi.interceptors.request.use(
                config => {
                    const token = localStorage.getItem('access_token');
                    if (token) {
                        config.headers.Authorization = `Bearer ${token}`;
                    }
                    return config;
                },
                error => Promise.reject(error)
            );

            privateApi.interceptors.response.use(
                response => response,
                async error => {
                    const originalRequest = error.config;
                    if (error.code === 'ERR_NETWORK') {
                        return Promise.reject(error);
                    }
                    if (error.response && error.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true;
                        try {
                            const refreshToken = localStorage.getItem('refresh_token');
                            const { data } = await publicApi.post('/auth/refresh', { refreshToken });
                            localStorage.setItem('access_token', data.access_token);
                            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
                            return axios(originalRequest);
                        } catch (error) {
                            // Handle refresh token error or redirect to login
                        }
                    }
                    return Promise.reject(error);
                }
            );

            const publicApi = axios.create({
                baseURL: apiConfig.webCrmApi,
            });

            return { privateApi, publicApi };
        })()
    }
};

export const { privateApi: PROTECTED, publicApi: PUBLIC } = API.CRM.AXIOS;
