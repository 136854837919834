import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from '../../features/ui/uiSlice'
import { useLocation, useNavigate } from "react-router-dom";
import { loginWebCrm } from '../../features/auth/newAuth';
import "./BasicAuth.css"

const BasicAuth = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [stayLogged, setStayLogged] = useState(true)

    const { access } = useSelector(state => state.auth)

    const onSignIn = e => {
        e.preventDefault()
        // dispatch(loginWithPass({ login, password, navigate, needRefresh: true }))
        dispatch(loginWebCrm({ login, password, navigate, stayLogged, location }))
    }

    const handleLogin = e => setLogin(e.target.value)
    const handlePassword = e => setPassword(e.target.value)
    const changeRememberMe = e => setStayLogged(e.target.checked)

    useEffect(() => {
        if (access) navigate('/')
    }, [access])

    return (
        <form className="auth_block" onSubmit={onSignIn} >
            <div className='auth_block_content'>
                <input className='auth_block_input' placeholder='Пользователь' onChange={handleLogin} />
                <input className='auth_block_input' type="password" placeholder='Пароль' onChange={handlePassword} />
                <label className='auth_block_remember_me'>
                    <input type="checkbox" checked={stayLogged} onChange={changeRememberMe} />
                    <p className='auth_block_remember_me_title'>Запомнить меня</p>
                </label>
                <button className='auth_block_sign-in' onClick={signIn}>Войти</button>
            </div>
        </form>
    )
}

export default BasicAuth