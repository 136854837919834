import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from './api'
import { checkErrorCode } from './error'
import { useSelector } from 'react-redux'

export const hasPermission = (permissions, permission) => permissions[permission]

export const usePermission = (permission) => {
    const userData = useSelector(state => state.auth.userData)
    return userData ? hasPermission(userData.permissions, permission) : false
}

const getRedirectParam = (search) => search ? new URLSearchParams(search).get('redirect') : null
const isLocalRedirect = (search) => {
    const redirect = getRedirectParam(search)
    return redirect && redirect.startsWith('/') && !redirect.includes('://')
}
const getRedirect = (search, defaultRedirect) => {
    const redirect = getRedirectParam(search)
    return isLocalRedirect(search) ? redirect : defaultRedirect
}

const initialState = {
    userData: null,
    loading: true,
    error: false,
    ok: false,
    access: false,
    message: '',
    access_token: '',
    refresh_token: '',
    stayLogged: false,
}

export const hasAccess = createAsyncThunk('auth/hasAccess', async (_, { dispatch }) => {
    try {
        const response = await API.CRM.AXIOS.privateApi.get('/auth/access')
        dispatch(setUserData(response.data))
        dispatch(setAccess(true))
    } catch (e) {
        dispatch(setUserData(null))
        dispatch(setAccess(false))
    }
})



export const loginWebCrm = createAsyncThunk(
    'auth/loginWebCrm',
    async ({ login, password, navigate, stayLogged, location }, { dispatch }) => {
        try {
            dispatch(setError(false))
            dispatch(setMessage(''))
            dispatch(setLoading(true))

            const { data: { access_token, refresh_token } } = await API.CRM.AXIOS.publicApi.post('/auth/signin', { login, password })

            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)

            dispatch(setAccessToken(access_token))
            dispatch(setRefreshToken(refresh_token))

            dispatch(setStayLogged(stayLogged))

            dispatch(setOk(true))
            dispatch(setMessage(`Добро пожаловать, ${login}`))

            dispatch(setAccess(true))

            navigate(getRedirect(location.search, '/') || '/', { replace: true })
            dispatch(hasAccess())
            dispatch(reset())
        } catch (e) {
            const msg = checkErrorCode(e?.response?.data?.code, 'Произошла ошибка')

            dispatch(setError(true))
            dispatch(setMessage(msg))
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setError: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        setOk: (state, { payload }) => {
            state.loading = false
            state.ok = payload
        },
        setMessage: (state, { payload }) => {
            state.message = payload
        },
        reset: state => {
            state.loading = false
            state.error = false
            state.ok = false
            state.message = ''
        },
        logout: state => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            state.loading = false
            state.error = false
            state.ok = false
            state.message = ''
            state.access = false
            state.access_token = ''
            state.refresh_token = ''
            state.userData = null
        },
        setAccessToken: (state, { payload }) => {
            state.access_token = payload
        },
        setRefreshToken: (state, { payload }) => {
            state.refresh_token = payload
        },
        setAccess: (state, { payload }) => {
            state.access = payload
        },
        setStayLogged: (state, { payload }) => {
            state.stayLogged = payload
        },
        setUserData: (state, { payload }) => {
            state.userData = payload
        },
    },
    extraReducers: builder => {
        builder.addCase(hasAccess.pending, state => {
            state.loading = true
        })
        builder.addCase(hasAccess.rejected, state => {
            state.loading = false
        })
        builder.addCase(hasAccess.fulfilled, state => {
            state.loading = false
        })
    },
})

export const {
    setLoading,
    setError,
    setOk,
    setMessage,
    reset,
    setAccessToken,
    setRefreshToken,
    setAccess,
    logout,
    setStayLogged,
    setUserData,
} = authSlice.actions

export default authSlice.reducer
