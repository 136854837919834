import axios from "axios";
import { REACT_APP_PRODUCTION, REACT_APP_NEST_API, REACT_APP_WEBCRM_API } from "../../consts/consts";

export const nestApi = axios.create({
    baseURL: REACT_APP_NEST_API
});

export const webCrmApi = axios.create({
    baseURL: REACT_APP_WEBCRM_API
});

console.log('Base url is taken from', REACT_APP_PRODUCTION ? '"Production"' : '"Development"',)