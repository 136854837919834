import Dashboard from "./components/dashboard/Dashboard";
import ChoiceSubdivisions from "./components/choice/ChoiseSubdivisions";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BasicAuth from "./app/auth/BasicAuth";
import Auth from "./app/auth/Auth";
import { hasAccess } from "./features/auth/newAuth";
import { useEffect, useState } from "react";
import { ProtectedRoute } from "./app/ProtectedRoute/ProtectedRoute";
import Loading from "./components/loading/Loading";

function App() {

    const { implementers } = useSelector((state) => state.implementers.subdivisions);

    const [loading, setLoading] = useState(true)
    const { access } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchData() {
            await dispatch(hasAccess())
            setLoading(false)
        }
        fetchData()
    }, [dispatch])

    if (loading) return <Loading />

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ProtectedRoute canUseRouteIf={!!access} ifNotAllowedPath="/login">
                        {implementers?.length ? <Dashboard implementers={implementers} /> : <ChoiceSubdivisions />}
                    </ProtectedRoute>
                }
            />
            <Route path="/login" element={<BasicAuth />}/>
        </Routes>
    );
}

export default App;
